import {
  BzDateFns,
  bzOptional,
  CompanyGuid,
  DayOfTheWeek,
  DAYS_OF_THE_WEEK,
  emailAddressValueSchema,
  firstNameSchema,
  guidSchema,
  HtmlString,
  lastNameSchema,
  localDateSchema,
  ONLINE_BOOKING_SERVICE_TYPES,
  OnlineBookingEarliestAvailableTime,
  OnlineBookingLatestAvailableTime,
  OnlineBookingServiceType,
  OnlineBookingType,
  PhoneNumberTypeSchema,
  telephoneNumberSchema,
  TimeZoneId,
} from '@breezy/shared'
import React, { useMemo } from 'react'
import { useQuery } from 'urql'
import z from 'zod'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { AccountExperienceOnlineBookingConfigQuery } from '../../generated/user/graphql'
import { useStrictContext } from '../../utils/react-utils'
import {
  ACCOUNT_EXPERIENCE_ONLINE_BOOKING_COMPANY_CONFIG_QUERY,
  ACCOUNT_EXPERIENCE_ONLINE_BOOKING_CONFIG_QUERY,
} from './OnlineBooking.gql'

export type OnlineBookingCompanyConfigContextType = {
  tzId: TimeZoneId
  limitBookingsToServiceAreaEnabled: boolean
  brandingColorHex: string
  afterHoursBannerHtml?: HtmlString
  serviceAreaZipCodes: string[]
}

export const OnlineBookingCompanyConfigContext = React.createContext<
  OnlineBookingCompanyConfigContextType | undefined
>(undefined)

export type ServiceTypeConfig = {
  bookingType: OnlineBookingType
  earliestAvailability?: OnlineBookingEarliestAvailableTime
  latestAvailability?: OnlineBookingLatestAvailableTime
  legalBlurb: string
  bookableJobTypes: BookableJobType[]
  weeklySchedule: Record<DayOfTheWeek, WeeklyScheduleDay>
}

export type OnlineBookingServiceTypeContextType = Record<
  OnlineBookingServiceType,
  ServiceTypeConfig
>

export const OnlineBookingServiceTypeConfigContext = React.createContext<
  OnlineBookingServiceTypeContextType | undefined
>(undefined)

export const RequestDetailsSubFormSchema = z.object({
  requestType: z.enum(ONLINE_BOOKING_SERVICE_TYPES),
  requestDetails: z.string(),
  jobTypeGuid: bzOptional(guidSchema),
})

export type RequestDetailsSubFormData = z.infer<
  typeof RequestDetailsSubFormSchema
>

export const AvailabilitySubFormSchema = z
  .object({
    preferredAvailabilityDate: localDateSchema,
    preferredAvailabilityTimeWindows: z
      .string()
      .array()
      .min(1, { message: 'Please select at least one time window' }),
    backupAvailabilityDate: bzOptional(localDateSchema),
    backupAvailabilityTimeWindows: bzOptional(
      z
        .string()
        .array()
        .min(1, { message: 'Please select at least one time window' }),
    ),
  })
  .refine(
    s => s.backupAvailabilityDate !== s.preferredAvailabilityDate,
    'Please select two unique availability dates',
  )

export type AvailabilitySubFormData = z.infer<typeof AvailabilitySubFormSchema>

export const InstantBookingSubFormSchema = z.object({
  date: localDateSchema,
  companyAppointmentArrivalWindowGuid: guidSchema,
})

export type InstantBookingSubFormData = z.infer<
  typeof InstantBookingSubFormSchema
>

export const ContactSubFormSchema = z.object({
  existingCustomer: z.boolean(),
  serviceAddressLine1: z.string().describe('Service Location Line 1'),
  serviceAddressLine2: bzOptional(
    z.string().describe('Service Location Line 2'),
  ),
  serviceAddressCity: z.string().describe('Service Location City'),
  serviceAddressStateAbbreviation: z
    .string()
    .describe('Service Location State'),
  serviceAddressZipCode: z.string().describe('Service Location Zip Code'),
  newContact: bzOptional(
    z.object({
      firstName: firstNameSchema,
      lastName: lastNameSchema,
      phoneNumber: telephoneNumberSchema,
      phoneNumberType: PhoneNumberTypeSchema,
      primaryEmailAddress: bzOptional(emailAddressValueSchema),
    }),
  ),
})

export type ContactSubFormData = z.infer<typeof ContactSubFormSchema>

export const ServiceRequestSchema = RequestDetailsSubFormSchema.and(
  AvailabilitySubFormSchema,
).and(ContactSubFormSchema)

export type ServiceRequestData = z.infer<typeof ServiceRequestSchema>

export type OnlineBookingCompanyConfig = NonNullable<
  AccountExperienceOnlineBookingConfigQuery['companiesByPk']
>['onlineBookingCompanyConfig']

export type OnlineBookingServiceTypeConfig = NonNullable<
  AccountExperienceOnlineBookingConfigQuery['companiesByPk']
>['onlineBookingServiceTypeConfigs'][number]

export type FetchedServiceTypes = NonNullable<
  AccountExperienceOnlineBookingConfigQuery['companiesByPk']
>['onlineBookingServiceTypeConfigs']

export type BookableJobType = NonNullable<
  OnlineBookingServiceTypeConfig['bookableJobTypes']
>[number]['jobType']

export type BookableArrivalWindow = NonNullable<
  OnlineBookingServiceTypeConfig['instantBookingWeeklyScheduleEntries']
>[number]['bookableArrivalWindows'][number]['arrivalWindow']

export type WeeklyScheduleDay = {
  isEnabled: boolean
  bookableArrivalWindows: BookableArrivalWindow[]
}

const defaultWeeklySchedule = DAYS_OF_THE_WEEK.reduce(
  (acc, day) => ({
    ...acc,
    [day]: { isEnabled: false, bookableArrivalWindows: [] },
  }),
  {} as Record<DayOfTheWeek, WeeklyScheduleDay>,
)

const defaultServiceTypeConfig: ServiceTypeConfig = {
  bookingType: 'REQUEST',
  earliestAvailability: '1 day',
  latestAvailability: '45 days',
  legalBlurb: '',
  bookableJobTypes: [],
  weeklySchedule: defaultWeeklySchedule,
}

const useOnlineBookingServiceTypes = (
  fetchedServiceTypes: FetchedServiceTypes,
): OnlineBookingServiceTypeContextType => {
  return useMemo(() => {
    const configs = ONLINE_BOOKING_SERVICE_TYPES.reduce(
      (acc, serviceType) => ({
        ...acc,
        [serviceType]: { ...defaultServiceTypeConfig },
      }),
      {} as OnlineBookingServiceTypeContextType,
    )

    fetchedServiceTypes.forEach(serviceType => {
      configs[serviceType.serviceType] = {
        bookingType: serviceType.bookingType,
        earliestAvailability: serviceType.earliestAvailability,
        latestAvailability: serviceType.latestAvailability,
        legalBlurb: serviceType.legalBlurb ?? '',
        bookableJobTypes:
          serviceType.bookableJobTypes?.map(bjt => bjt.jobType) ?? [],
        weeklySchedule: serviceType.instantBookingWeeklyScheduleEntries?.reduce(
          (acc, entry) => ({
            ...acc,
            [entry.dayOfWeek]: {
              isEnabled: entry.isEnabled,
              bookableArrivalWindows:
                entry.bookableArrivalWindows?.map(baw => baw.arrivalWindow) ??
                [],
            },
          }),
          defaultWeeklySchedule,
        ),
      }
    })

    return configs
  }, [fetchedServiceTypes])
}

const useOnlineBookingCompanyConfig = (
  fetchedCompanyConfig: OnlineBookingCompanyConfig,
) => {
  return useMemo(
    () => ({
      limitBookingsToServiceAreaEnabled:
        fetchedCompanyConfig?.limitBookingsToServiceAreaEnabled ?? false,
      brandingColorHex: fetchedCompanyConfig?.brandingColorHex ?? '#1677FF',
      afterHoursBannerHtml: fetchedCompanyConfig?.afterHoursBannerHtml ?? '',
      serviceAreaZipCodes:
        fetchedCompanyConfig?.serviceAreaZipCodes.map(
          zipCode => zipCode.zipCode,
        ) ?? [],
    }),
    [fetchedCompanyConfig],
  )
}

export const useBookableJobTypes = (serviceType: OnlineBookingServiceType) => {
  const serviceTypeConfigs = useStrictContext(
    OnlineBookingServiceTypeConfigContext,
  )

  return serviceTypeConfigs[serviceType]?.bookableJobTypes ?? []
}

const useFetchOnlineBookingConfigs = (companyGuid: CompanyGuid) => {
  const onlineBookingConfigQuery = useQuery({
    query: ACCOUNT_EXPERIENCE_ONLINE_BOOKING_CONFIG_QUERY,
    variables: {
      companyGuid,
    },
  })

  const [{ data, fetching }] = onlineBookingConfigQuery

  const serviceTypeConfigs = useOnlineBookingServiceTypes(
    data?.companiesByPk?.onlineBookingServiceTypeConfigs ?? [],
  )

  const onlineBookingCompanyConfig = useOnlineBookingCompanyConfig(
    data?.companiesByPk?.onlineBookingCompanyConfig,
  )

  const tzId = useMemo(
    () => data?.companiesByPk?.tzId ?? BzDateFns.NY_TZ,
    [data],
  )

  return {
    tzId,
    onlineBookingConfigQuery,
    onlineBookingCompanyConfig: {
      ...onlineBookingCompanyConfig,
      tzId,
    },
    onlineBookingServiceTypeConfigs: serviceTypeConfigs,
    isLoading: fetching ?? false,
  }
}

const useFetchOnlineBookingCompanyConfig = (companyGuid: CompanyGuid) => {
  const onlineBookingConfigQuery = useQuery({
    query: ACCOUNT_EXPERIENCE_ONLINE_BOOKING_COMPANY_CONFIG_QUERY,
    variables: { companyGuid },
  })

  const [{ data, fetching }] = onlineBookingConfigQuery
  const tzId = useMemo(
    () => data?.companiesByPk?.tzId ?? BzDateFns.NY_TZ,
    [data],
  )

  return {
    onlineBookingCompanyConfig: {
      ...useOnlineBookingCompanyConfig(
        data?.companiesByPk?.onlineBookingCompanyConfig,
      ),
      tzId,
    },
    isLoading: fetching ?? false,
  }
}

type WithOnlineBookingContextsProps = React.PropsWithChildren<{
  companyGuid: CompanyGuid
  loadingComponent?: JSX.Element
}>

export const WithOnlineBookingContexts =
  React.memo<WithOnlineBookingContextsProps>(
    ({ children, companyGuid, loadingComponent }) => {
      const {
        isLoading,
        onlineBookingCompanyConfig,
        onlineBookingServiceTypeConfigs,
      } = useFetchOnlineBookingConfigs(companyGuid)

      if (isLoading) {
        return loadingComponent ?? <LoadingSpinner />
      }

      return (
        <OnlineBookingCompanyConfigContext.Provider
          value={onlineBookingCompanyConfig}
        >
          <OnlineBookingServiceTypeConfigContext.Provider
            value={onlineBookingServiceTypeConfigs}
          >
            {children}
          </OnlineBookingServiceTypeConfigContext.Provider>
        </OnlineBookingCompanyConfigContext.Provider>
      )
    },
  )

type WithOnlineBookingCompanyConfigContextProps = React.PropsWithChildren<{
  companyGuid: CompanyGuid
  loadingComponent?: JSX.Element
}>

export const WithOnlineBookingCompanyConfigContext =
  React.memo<WithOnlineBookingCompanyConfigContextProps>(
    ({ children, companyGuid, loadingComponent }) => {
      const { isLoading, onlineBookingCompanyConfig } =
        useFetchOnlineBookingCompanyConfig(companyGuid)

      if (isLoading) {
        return loadingComponent ?? <LoadingSpinner />
      }

      return (
        <OnlineBookingCompanyConfigContext.Provider
          value={onlineBookingCompanyConfig}
        >
          {children}
        </OnlineBookingCompanyConfigContext.Provider>
      )
    },
  )
