import { gql } from '../../generated'

export const ACCOUNT_EXPERIENCE_ONLINE_BOOKING_COMPANY_CONFIG_FRAGMENT =
  gql(/* GraphQL */ `
    fragment AccountExperienceOnlineBookingCompanyConfig on OnlineBookingCompanyConfig {
      limitBookingsToServiceAreaEnabled
      brandingColorHex
      afterHoursBannerHtml
      serviceAreaZipCodes {
        zipCode
      }
    }
  `)

export const ACCOUNT_EXPERIENCE_ONLINE_BOOKING_SERVICE_TYPE_CONFIG_FRAGMENT =
  gql(/* GraphQL */ `
    fragment AccountExperienceOnlineBookingServiceTypeConfig on OnlineBookingServiceTypeConfigs {
      onlineBookingServiceTypeGuid
      companyGuid
      serviceType
      bookingType
      earliestAvailability
      latestAvailability
      legalBlurb
      bookableJobTypes {
        jobType {
          jobTypeGuid
          name
          jobClass
        }
      }
      instantBookingWeeklyScheduleEntries {
        dayOfWeek
        isEnabled
        bookableArrivalWindows {
          arrivalWindow {
            companyAppointmentArrivalWindowGuid
            arrivalWindowStartTime
            arrivalWindowEndTime
          }
        }
      }
    }
  `)

export const ACCOUNT_EXPERIENCE_ONLINE_BOOKING_CONFIG_QUERY =
  gql(/* GraphQL */ `
    query AccountExperienceOnlineBookingConfig($companyGuid: uuid!) {
      companiesByPk(companyGuid: $companyGuid) {
        tzId: timezone
        onlineBookingCompanyConfig {
          ...AccountExperienceOnlineBookingCompanyConfig
        }
        onlineBookingServiceTypeConfigs {
          ...AccountExperienceOnlineBookingServiceTypeConfig
        }
      }
    }
  `)

export const ACCOUNT_EXPERIENCE_ONLINE_BOOKING_COMPANY_CONFIG_QUERY =
  gql(/* GraphQL */ `
    query AccountExperienceOnlineBookingCompanyConfig($companyGuid: uuid!) {
      companiesByPk(companyGuid: $companyGuid) {
        tzId: timezone
        onlineBookingCompanyConfig {
          ...AccountExperienceOnlineBookingCompanyConfig
        }
      }
    }
  `)
