import { faBolt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const InstantBookingIcon = React.memo(() => {
  return (
    <div className="flex items-center gap-[6px] rounded-md border border-solid border-bz-border px-2 py-[5px] text-sm leading-[22px] text-bz-green-700">
      <div className="flex h-4 w-4 flex-col items-center justify-center rounded-full bg-bz-green-200 p-[2px]">
        <FontAwesomeIcon className="h-3 w-3" icon={faBolt} />
      </div>
      <div>Instant Booking</div>
    </div>
  )
})
