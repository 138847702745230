import {
  BzDateFns,
  CompanyGuid,
  getEarliestAvailableDate,
  getLastestAvailableDate,
  OnlineBookingServiceType,
} from '@breezy/shared'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Skeleton } from 'antd'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Calendar } from '../../components/DatePicker/DatePicker'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { trpc } from '../../hooks/trpc'
import { useStrictContext } from '../../utils/react-utils'
import './OnlineBookingInstantBookingSubForm.less'
import {
  InstantBookingSubFormData,
  OnlineBookingCompanyConfigContext,
  ServiceTypeConfig,
} from './onlineBookingUtils'

type OnlineBookingInstantBookingSubFormProps = {
  companyGuid: CompanyGuid
  serviceType: OnlineBookingServiceType
  serviceTypeConfig: ServiceTypeConfig
  jobTypeGuid: string
}
export const OnlineBookingInstantBookingSubForm =
  React.memo<OnlineBookingInstantBookingSubFormProps>(
    ({ companyGuid, serviceType, serviceTypeConfig, jobTypeGuid }) => {
      const { tzId } = useStrictContext(OnlineBookingCompanyConfigContext)

      const {
        control,
        formState: { errors },
        watch,
      } = useFormContext<InstantBookingSubFormData>()

      const selectedDate = watch('date')

      const scheduleAvailabilityQuery = trpc.onlineBooking[
        'unauth:online-booking:instant-booking:schedule-availability'
      ].useQuery({
        companyGuid: companyGuid,
        date: selectedDate,
        serviceType: serviceType,
        jobTypeGuid: jobTypeGuid,
      })

      const [earliestAvailableDate, latestAvailableDate] = useMemo(
        () => [
          getEarliestAvailableDate(
            BzDateFns.getTodayLocalDate(tzId),
            serviceTypeConfig.earliestAvailability ?? '12 hours',
          ),
          getLastestAvailableDate(
            BzDateFns.getTodayLocalDate(tzId),
            serviceTypeConfig.latestAvailability ?? '60 days',
          ),
        ],
        [
          serviceTypeConfig.earliestAvailability,
          serviceTypeConfig.latestAvailability,
          tzId,
        ],
      )

      const canMoveBackAMonth = useMemo(() => {
        const nextMonthStart = BzDateFns.startOfMonth(
          BzDateFns.addMonths(
            BzDateFns.parseLocalDate(BzDateFns.getTodayLocalDate(tzId)),
            1,
          ),
        )

        return (
          BzDateFns.isSameDay(
            nextMonthStart,
            BzDateFns.parseLocalDate(selectedDate),
          ) ||
          BzDateFns.isAfter(
            BzDateFns.parseLocalDate(selectedDate),
            nextMonthStart,
          )
        )
      }, [selectedDate, tzId])

      const canMoveForwardAMonth = useMemo(() => {
        const followingMonthStart = BzDateFns.startOfMonth(
          BzDateFns.addMonths(BzDateFns.parseLocalDate(selectedDate), 1),
        )

        return BzDateFns.isBefore(followingMonthStart, latestAvailableDate)
      }, [selectedDate, latestAvailableDate])

      return (
        <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-6">
          <ReactHookFormItem
            control={control}
            outerClassName="col-span-1 md:col-span-4"
            name="date"
            required
            noBottomMargin
            errors={errors}
            render={({ field }) => (
              <Calendar
                fullscreen={false}
                mode="month"
                className="instant-booking-calendar"
                value={BzDateFns.parseLocalDate(field.value)}
                disabledDate={current => {
                  if (!current) return false

                  return (
                    BzDateFns.isBefore(current, earliestAvailableDate) ||
                    BzDateFns.isAfter(current, latestAvailableDate)
                  )
                }}
                headerRender={val => {
                  return (
                    <div className="mb-4 flex w-fit items-center justify-between gap-x-3">
                      <Button
                        className="flex h-8 w-8 flex-col items-center justify-center rounded-full"
                        disabled={!canMoveBackAMonth}
                        onClick={() => {
                          const oneMonthAgo = BzDateFns.subMonths(val.value, 1)
                          field.onChange(
                            BzDateFns.formatLocalDate(
                              BzDateFns.isAfter(
                                oneMonthAgo,
                                earliestAvailableDate,
                              )
                                ? oneMonthAgo
                                : earliestAvailableDate,
                            ),
                          )
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="text-lg text-gray-700"
                        />
                      </Button>
                      <div className="min-w-[100px] text-center text-sm leading-[22px]">
                        {`${BzDateFns.format(val.value, 'MMMM yyyy')}`}
                      </div>
                      <Button
                        className="flex h-8 w-8 flex-col items-center justify-center rounded-full"
                        disabled={!canMoveForwardAMonth}
                        onClick={() => {
                          const nextMonth = BzDateFns.addMonths(val.value, 1)
                          field.onChange(
                            BzDateFns.formatLocalDate(
                              BzDateFns.isBefore(nextMonth, latestAvailableDate)
                                ? nextMonth
                                : latestAvailableDate,
                            ),
                          )
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="text-lg text-gray-700"
                        />
                      </Button>
                    </div>
                  )
                }}
                onSelect={date => {
                  field.onChange(BzDateFns.formatLocalDate(date))
                }}
              />
            )}
          />
          <ReactHookFormItem
            control={control}
            outerClassName="col-span-1 md:col-span-2"
            name="companyAppointmentArrivalWindowGuid"
            required
            noBottomMargin
            errors={errors}
            render={({ field }) => (
              <div className="flex flex-col gap-y-6">
                <div className="text-sm leading-[22px]">
                  {`${BzDateFns.format(
                    BzDateFns.parseLocalDate(selectedDate),
                    'EEEE, MMMM d',
                  )}`}
                </div>
                <TrpcQueryLoader
                  query={scheduleAvailabilityQuery}
                  loadingComponent={
                    <div className="flex flex-col gap-y-2">
                      {Array.from({ length: 2 }).map((_, index) => (
                        <Skeleton.Input
                          key={index}
                          className="w-full rounded-md"
                          active
                        />
                      ))}
                    </div>
                  }
                  render={data => {
                    if (data.arrivalWindows.length === 0) {
                      return <EmptyScheduleAvailability />
                    }
                    return (
                      <div className="flex flex-col gap-y-3">
                        {data.arrivalWindows.map(window => (
                          <Button
                            className="w-full rounded-md"
                            key={window.companyAppointmentArrivalWindowGuid}
                          >
                            {`${BzDateFns.localTimeStringToFormattedTimeString(
                              window.arrivalWindowStartTime,
                              false,
                            )} - ${BzDateFns.localTimeStringToFormattedTimeString(
                              window.arrivalWindowEndTime,
                              false,
                            )}`}
                          </Button>
                        ))}
                      </div>
                    )
                  }}
                />
              </div>
            )}
          />
        </div>
      )
    },
  )

const EmptyScheduleAvailability = () => (
  <div className="w-full flex-1 rounded-md border border-solid border-bz-border bg-bz-fill-quaternary p-3 text-bz-text-tertiary shadow-sm">
    No times available. Please select a different day
  </div>
)
